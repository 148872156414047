<template>
  <div>
    <div class="note note-danger">
      <div class="note-icon"><i class="fa fa-exclamation"></i></div>
      <div class="note-content">
        <h4><b>404 Not Found!</b></h4>
        <p>Halaman Tidak Ditemukan</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFundPage",
};
</script>